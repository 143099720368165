define("ember-modal-dialog/templates/components/liquid-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0VfOzLry",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"isOverlaySibling\"]]],null,{\"statements\":[[4,\"liquid-wormhole\",null,[[\"stack\",\"value\",\"class\"],[[23,[\"stack\"]],[23,[\"value\"]],[27,\"concat\",[\"liquid-dialog-container \",[23,[\"wrapperClassNamesString\"]],\" \",[23,[\"wrapperClass\"]]],null]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[[21,\"wrapperClassNamesString\"],\" \",[21,\"wrapperClass\"]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"hasOverlay\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[12,\"class\",[21,\"overlayClassNamesString\"]],[12,\"onclick\",[27,\"action\",[[22,0,[]],[23,[\"onClickOverlay\"]]],null]],[11,\"tabindex\",\"-1\"],[11,\"data-emd-overlay\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"div\"],[12,\"class\",[21,\"containerClassNamesString\"]],[9],[0,\"\\n        \"],[14,1],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"liquid-wormhole\",null,[[\"stack\",\"value\",\"class\"],[[23,[\"stack\"]],[23,[\"value\"]],[27,\"concat\",[\"liquid-dialog-container \",[23,[\"wrapperClassNamesString\"]],\" \",[23,[\"wrapperClass\"]]],null]]],{\"statements\":[[4,\"if\",[[23,[\"hasOverlay\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"class\",[21,\"overlayClassNamesString\"]],[12,\"onclick\",[27,\"action\",[[22,0,[]],[27,\"ignore-children\",[[23,[\"onClickOverlay\"]]],null]],null]],[11,\"tabindex\",\"-1\"],[11,\"data-emd-overlay\",\"\"],[9],[0,\"\\n        \"],[7,\"div\"],[12,\"class\",[21,\"containerClassNamesString\"]],[9],[0,\"\\n          \"],[14,1],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"class\",[21,\"containerClassNamesString\"]],[9],[0,\"\\n        \"],[14,1],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/liquid-dialog.hbs"
    }
  });

  _exports.default = _default;
});