define("ember-highcharts/templates/components/high-charts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QcvoV2P3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"chart-container\"],[9],[10],[0,\"\\n\"],[14,1,[[23,[\"chart\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-highcharts/templates/components/high-charts.hbs"
    }
  });

  _exports.default = _default;
});