define("ember-cli-daterangepicker/templates/components/date-range-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/xWHAPgK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\"],[12,\"class\",[21,\"labelClass\"]],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[27,\"input\",null,[[\"value\",\"class\",\"placeholder\"],[[23,[\"rangeText\"]],[23,[\"inputClasses\"]],[23,[\"placeholder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-daterangepicker/templates/components/date-range-picker.hbs"
    }
  });

  _exports.default = _default;
});